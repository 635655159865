import React from "react"

import MainNavBar from "../mainNavBar"
import CaptionCookie from "./captionCookie"

const HeaderCookie = ({ siteTitleContact }) => (
  <header className="headerCookie">
    <a href="tel:18003582468" className="topRightPhone">1-800-358-2468</a>
    <MainNavBar />
    <CaptionCookie />
  </header>
)

export default HeaderCookie
