import React from "react"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


const CaptionCookie = () => (
  <Container>
        <Row>
            <Col>
                <div className="captionCookie">
                    <h1>About cookies</h1>
                    <p>General information about cookies and how they are used</p>
                </div>
            </Col>
        </Row>
  </Container>
)


export default CaptionCookie
